import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { injectIntl } from 'react-intl';
import logoBreedj from '../../assets/images/new-logo-breedj.png';
import { useDispatch, useSelector } from 'react-redux';
import { acceptCguvRequest } from '../../redux/api/Auth';
import { useNavigate } from 'react-router-dom';
import { acceptCguv, userSignOut } from '../../redux/actions/Auth';
import BreedjCguvEn from '../../assets/documents/Breedj_cguv_en_2024.pdf';
import BreedjCguvFr from '../../assets/documents/Breedj_cguv_fr_2024.pdf';


const AcceptCguvModal = ({intl,showModalBreedj,setShowModalBreedj}) => {
    const [cguvAccepted, setCguvAccepted] = useState(false);
    const {token, user} = useSelector(({auth}) => auth)
    const {locale} = useSelector(({common}) => common)
    let navigate = useNavigate()
    const dispatch = useDispatch()
    let fromTalent = '/talent/dashboard'
    let fromCompany = '/company/dashboard'

    const handleCloseBreedjModal = () => {
        dispatch()
    }
    

    const handleCheckboxChange =  (e) => {
        
        if(token) {
           dispatch(acceptCguv(token));
            // acceptCguvRequest(token)
            setCguvAccepted(e.target.value);
        }
    };
    
    const handleAcceptCguv = () => {

            if (user.role === 'talent') {
                navigate(fromTalent, { replace: true });
            } else if (user.role === 'company') {
                navigate(fromCompany, { replace: true });
            }
    }

    const handleRejectedCguv = () => {
        dispatch(userSignOut(token))
        setShowModalBreedj(false)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        outline: 'none',
        p: 4,
        borderRadius: '16px',
      };
  return (
        <Modal
        open={showModalBreedj}
        // onClose={handleCloseBreedjModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
         >
        <Box sx={style}>
        <div className='flex justify-center items-center'>
                <img src={logoBreedj} className='w-52' />
        </div>
        
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        {intl.formatMessage({id:"migrationTalenteumToBreedjMessage"})}
        </Typography>
        <div className='text-sm mt-3'> 
        <div className="flex items-center">
            <input 
                type="checkbox" 
                checked={cguvAccepted} 
                onChange={handleCheckboxChange} 
                className="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring focus:ring-blue-500"
            />
            <label  className="ml-2 text-sm text-gray-700">
                {intl.formatMessage({id:"ByClickingHere"})}{" "}
                <a href={locale.short === "en" ? BreedjCguvEn : BreedjCguvFr} className="text-[#002496] underline">
                    {intl.formatMessage({id:"cgu.cgv"})}
                </a>.
            </label>
        </div>
        </div>
        <div className='flex items-center gap-2 mt-4'>
            <button 
                    onClick={handleAcceptCguv}
                    className={`px-8 py-2 bg-secondary rounded-full text-white w-full 
                    ${!cguvAccepted ? "opacity-50 cursor-not-allowed" : ""}`}
                disabled={!cguvAccepted}
            >
                {intl.formatMessage({id:"IConnect"})}
            </button>
            <button 
                onClick={handleRejectedCguv}
                className="px-8 py-2 shadow-xl text-[bgSecondary] rounded-full w-full"
            >
                {intl.formatMessage({id:'reject'})}
            </button>
        </div>

        </Box>
    </Modal>
  )
}

export default injectIntl(AcceptCguvModal) 