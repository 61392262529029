import {axiosInstance, axiosInstanceGraphQL} from '../../axios/axios'

export const getChatgptRequest = async (token, chat_type, params) =>
    await axiosInstance.post('/api/chatgpt',{
        type: chat_type,
        params: params
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

        export const ChatgptESRequest = async (token, message) =>
            await axiosInstance
              .post('/api/chat/es', { message }, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then(response => response)
              .catch(error => error.response);
          


export const checkAutoJobRequest =  async (token, id) =>

    await axiosInstance.post('/api/job_offer/check_auto_finish/' + id, {}, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })

export const checkCensorTextRequest =  async (token, text) =>

    await axiosInstance.post('/api/chat/censor', {
        message: text
    }, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return error.response
        })