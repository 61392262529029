// Import the redux-saga/effects
import {call, put, takeEvery} from 'redux-saga/effects'

// Import all action and api's
import {
    GET_AVAILABILITY,
    GET_AVATAR,
    GET_CERTIFICATION,
    GET_CITIES,
    GET_CONTRACT_LEVEL,
    GET_CONTRACT_MODE,
    GET_CONTRACT_TIME,
    GET_CONTRACT_TYPE,
    GET_COUNTRIES,
    GET_CURRENCY,
    GET_EDUCATION,
    GET_EDUCATION_LEVEL,
    GET_EDUCATION_STATUS,
    GET_EXP_RANGE,
    GET_GENDER,
    GET_HOBBY,
    GET_SECTOR,
    GET_INDUSTRY,
    GET_SUB_INDUSTRY,
    GET_SUB_SUB_INDUSTRY,
    GET_LANGUAGE,
    GET_REF_JOB_TITLE,
    GET_SEARCH_URGENCY,
    GET_SKILL, GET_TIMEZONE,
    GET_UNIVERSITY, GET_VALUE,
    GET_WORK_LOCATION, GET_MODULES,
    GET_WORK_CONTRACT,
    GET_COUNTRY_COMPLIANCE
} from '../action-types'

// Import all api's
import {
    getAvailabilityRequest,
    getAvatarRequest,
    getCertificationRequest,
    getCityRequest,
    getContractLevelRequest,
    getContractModeRequest,
    getContractTimeRequest,
    getContractTypeRequest,
    getCountriesRequest,
    getCurrencyRequest,
    getEducationLevelRequest,
    getEducationRequest,
    getEducationStatusRequest,
    getExpRangeRequest,
    getGenderRequest,
    getHobbyRequest,
    getIndustryRequest,
    getSectorRequest,
    getSubIndustryRequest,
    getSubSubIndustryRequest,
    getLanguageRequest,
    getRefJobTitleRequest,
    getRefJobTitleRequestES,
    getRefUniversityRequest,
    getSearchUrgencyRequest,
    getSkillRequest, getTimezoneRequest, getValueRequest,
    getWorkLocationRequest, getModulesRequest, getWorkContractRequest,
    getCountryComplianceRequest
} from '../api/List'

import {
    getAvailabilitySuccess,
    getAvatarSuccess,
    getCertificationSuccess,
    getCitiesSuccess,
    getContractLevelSuccess,
    getContractModeSuccess,
    getContractTimeSuccess,
    getContractTypeSuccess,
    getCountriesSuccess, getCurrencySuccess,
    getEducationLevelSuccess,
    getEducationStatusSuccess,
    getEducationSuccess,
    getExpRangeSuccess,
    getGenderSuccess,
    getHobbySuccess,
    getSectorSuccess,
    getIndustrySuccess,
    getSubIndustrySuccess,
    getSubSubIndustrySuccess,
    getLanguageSuccess,
    getRefJobTitleSuccess,
    getRefUniversitySuccess,
    getSearchUrgencySuccess,
    getSkillSuccess, getTimezoneSuccess, getValueSuccess,
    getWorkLocationSuccess, getModulesSuccess, getWorkContractSuccess,
    getCountryComplianceSuccess
} from '../actions/List'

import {fetchError, fetchExpired, fetchStart, fetchSuccess} from '../actions/Common'

function* getCountries({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getCountriesRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getCountriesSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getEducationStatus({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getEducationStatusRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getEducationStatusSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getEducationLevel({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getEducationLevelRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getEducationLevelSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getEducation({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getEducationRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getEducationSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getCertification({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getCertificationRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getCertificationSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getLanguage({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getLanguageRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getLanguageSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getSkill({token, query, locale}) {
    try {
        ////yield put(fetchStart())
        const response = yield call(getSkillRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getSkillSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getHobby({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getHobbyRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getHobbySuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getSearchUrgency({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getSearchUrgencyRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getSearchUrgencySuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getExpRange({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getExpRangeRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getExpRangeSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getGender({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getGenderRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getGenderSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getSector({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getSectorRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getSectorSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getIndustry({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getIndustryRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getIndustrySuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getSubIndustry({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getSubIndustryRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getSubIndustrySuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getSubSubIndustry({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getSubSubIndustryRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getSubSubIndustrySuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getRefJobTitle({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getRefJobTitleRequestES, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getRefJobTitleSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getRefUniversity({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getRefUniversityRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getRefUniversitySuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getAvailability({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getAvailabilityRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getAvailabilitySuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getContractMode({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getContractModeRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getContractModeSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getContractType({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getContractTypeRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getContractTypeSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getContractTime({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getContractTimeRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getContractTimeSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getContractLevel({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getContractLevelRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getContractLevelSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getWorkLocation({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getWorkLocationRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getWorkLocationSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getAvatar({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getAvatarRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getAvatarSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getCities({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getCityRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getCitiesSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getCurrency({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getCurrencyRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getCurrencySuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getValue({token, query, locale}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getValueRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getValueSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getTimezone({token, query, locale}) {
    try {
        const response = yield call(getTimezoneRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getTimezoneSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getModules({token, query, locale}) {
    try {
        const response = yield call(getModulesRequest, token, query, locale)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getModulesSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {

            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getWorkContract({token}) {
    try {
        const response = yield call(getWorkContractRequest, token)
        if (response.status === 200) {
            yield put(getWorkContractSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {

            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getCountryCompliance({token}) {
    try {
        const response = yield call(getCountryComplianceRequest, token)
        if (response.status === 200) {
            yield put(getCountryComplianceSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {

            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

// Export the saga (todo-saga)
export default function* todoSaga() {
    yield takeEvery(GET_COUNTRIES, getCountries)
    yield takeEvery(GET_SEARCH_URGENCY, getSearchUrgency)
    yield takeEvery(GET_EXP_RANGE, getExpRange)
    yield takeEvery(GET_UNIVERSITY, getRefUniversity)
    yield takeEvery(GET_REF_JOB_TITLE, getRefJobTitle)
    yield takeEvery(GET_SKILL, getSkill)
    yield takeEvery(GET_HOBBY, getHobby)
    yield takeEvery(GET_LANGUAGE, getLanguage)
    yield takeEvery(GET_AVAILABILITY, getAvailability)
    yield takeEvery(GET_CONTRACT_MODE, getContractMode)
    yield takeEvery(GET_CONTRACT_TYPE, getContractType)
    yield takeEvery(GET_CONTRACT_TIME, getContractTime)
    yield takeEvery(GET_CONTRACT_LEVEL, getContractLevel)
    yield takeEvery(GET_WORK_LOCATION, getWorkLocation)
    yield takeEvery(GET_AVATAR, getAvatar)
    yield takeEvery(GET_GENDER, getGender)
    yield takeEvery(GET_CITIES, getCities)
    yield takeEvery(GET_EDUCATION_STATUS, getEducationStatus)
    yield takeEvery(GET_EDUCATION, getEducation)
    yield takeEvery(GET_EDUCATION_LEVEL, getEducationLevel)
    yield takeEvery(GET_CERTIFICATION, getCertification)
    yield takeEvery(GET_SECTOR, getSector)
    yield takeEvery(GET_INDUSTRY, getIndustry)
    yield takeEvery(GET_SUB_INDUSTRY, getSubIndustry)
    yield takeEvery(GET_SUB_SUB_INDUSTRY, getSubSubIndustry)
    yield takeEvery(GET_CURRENCY, getCurrency)
    yield takeEvery(GET_VALUE, getValue)
    yield takeEvery(GET_TIMEZONE, getTimezone)
    yield takeEvery(GET_MODULES, getModules)
    yield takeEvery(GET_WORK_CONTRACT, getWorkContract)
    yield takeEvery(GET_COUNTRY_COMPLIANCE, getCountryCompliance)
}