import {
    DELETE_TALENT_MULTIPLE,
    DELETE_TALENT_MULTIPLE_SUCCESS,
    GET_TALENT,
    GET_TALENT_SUCCESS,
    GET_TALENTS,
    GET_TALENTS_SUCCESS,
    PRESENTATION_TALENT,
    PRESENTATION_TALENT_SUCCESS,
    SALARY_DETAILS,
    SALARY_DETAILS_SUCCESS,
    SALARY_TAX_DETAILS,
    SALARY_TAX_DETAILS_SUCCESS,
    SET_TALENT_STATE,
    UPDATE_PROFILE_STATUS,
    UPDATE_PROFILE_STATUS_SUCCESS,
    UPDATE_SEMI_PROFILE,
    UPDATE_SEMI_PROFILE_SUCCESS,
    UPDATE_TALENT,
    UPDATE_TALENT_MULTIPLE,
    UPDATE_TALENT_MULTIPLE_LEVEL,
    UPDATE_TALENT_MULTIPLE_LEVEL_SUCCESS,
    UPDATE_TALENT_MULTIPLE_SUCCESS,
    UPDATE_TALENT_SUCCESS,
    REFETCH_TALENT,
    SKILL_LANG_HOBBY,
    GET_TALENTS_COUNT,
    GET_TALENTS_COUNT_SUCCESS,
    GET_TALENTS_ES,
    GET_TALENTS_ES_SUCCESS,
    GET_TALENTS_ES_RESET,
    GET_TIMESHEETS,
    GET_TIMESHEETS_SUCCESS,
    UPSERT_TIMESHEETS,
    UPSERT_TIMESHEETS_SUCCESS,
    UPSERT_EXPENSE_REPORTS_SUCCESS,
    UPSERT_EXPENSE_REPORTS,
    GET_EXPENSE_REPORTS_SUCCESS,
    GET_EXPENSE_REPORTS,
    DELETE_EXPENSE_REPORTS_SUCCESS,
    DELETE_EXPENSE_REPORTS,
    GET_CONTRACTOR_INVOICE,
    GET_CONTRACTOR_INVOICE_SUCCESS,
    UPSERT_CONTRACTOR_INVOICE_VALIDATION_ERROR,
    UPSERT_CONTRACTOR_INVOICE,
    UPSERT_CONTRACTOR_INVOICE_SUCCESS,
    GET_HOLIDAYS_SUCCESS, GET_HOLIDAYS,
    UPSERT_HOLIDAYS_SUCCESS, UPSERT_HOLIDAYS,
    GET_BONUS_SUCCESS, GET_BONUS,
    UPSERT_BONUS_SUCCESS, UPSERT_BONUS,
    UPSERT_SURVEY_SUCCESS, UPSERT_SURVEY,
    GET_CONTRACTOR_INVOICE_EXPLAIN,
    GET_CONTRACTOR_INVOICE_EXPLAIN_SUCCESS,
    GET_TALENTS_ES_RESET_PAGE,
    STORE_TALENT_RATING,
    STORE_TALENT_RATING_SUCCESS,
    UPDATE_TALENT_BANKING,
    UPDATE_TALENT_BANKING_SUCCESS,
    GET_TALENT_EMPLOYMENT,
    GET_TALENT_EMPLOYMENT_SUCCESS,
    GET_TALENTS_BY_IDS,
    GET_TALENTS_BY_IDS_SUCCESS,
    GET_TALENTS_BY_IDS_FAILURE
} from '../action-types'

export const getTalentsByIdsRequest = (token, ids) => ({
    type: GET_TALENTS_BY_IDS,
    payload: { token, ids }
});

export const getTalentsByIdsSuccess = (data) => ({
    type: GET_TALENTS_BY_IDS_SUCCESS,
    payload: data
});

export const getTalentsFailure = (error) => ({
    type: GET_TALENTS_BY_IDS_FAILURE,
    payload: error
});

export const getTalent = (token, query) => {
    return {
        type: GET_TALENT,
        token: token,
        query: query
    }
}

export const getTalentSuccess = (data) => {
    return {
        type: GET_TALENT_SUCCESS,
        data: data?.data,
    }
}

export const getTalents = (token, query) => {
    return {
        type: GET_TALENTS,
        token: token,
        query: query
    }
}

export const getTalentsSuccess = (data) => {
    return {
        type: GET_TALENTS_SUCCESS,
        data: data?.data,
    }
}

export const getTalentsES = (token, query, locale) => {
    return {
        type: GET_TALENTS_ES,
        token: token,
        query: query,
        locale: locale
    }
}

export const getTalentsESReset = () => {
    return {
        type: GET_TALENTS_ES_RESET
    }
}

export const getTalentsESResetPage = () => {
    return {
        type: GET_TALENTS_ES_RESET_PAGE
    }
}

export const getTalentsSuccessES = (data) => {
    let results = [];
    for (let i = 0; i < data?.hits?.hits?.length; i++) {
        results.push(data?.hits?.hits[i]?._source)
    }

    return {
        type: GET_TALENTS_ES_SUCCESS,
        data: results,
        talents_es_total: parseInt(data?.hits?.total?.value)
    }
}

export const getTalentsCount = (token, query) => {
    return {
        type: GET_TALENTS_COUNT,
        token: token,
        query: query
    }
}

export const storeTalentRatingSuccess = (data) => {
    return {
        type: STORE_TALENT_RATING_SUCCESS,
        data: data,
    }
}

export const storeTalentRating = (token, values, intl) => {
    return {
      type: STORE_TALENT_RATING,
      token: token,
      values: values,
      intl: intl
    };
  };

export const getTalentsCountSuccess = (data) => {
    return {
        type: GET_TALENTS_COUNT_SUCCESS,
        data: data?.data,
    }
}

export const updateTalent = (token, query, loading, reload = false, intl, typeUpdate) => {
    return {
        type: UPDATE_TALENT,
        token: token,
        query: query,
        loading: loading,
        reload: reload,
        intl: intl,
        typeUpdate: typeUpdate
    }
}

export const updateTalentSuccess = (data) => {
    return {
        type: UPDATE_TALENT_SUCCESS,
        data: data?.data,
    }
}

export const setTalentState = (state) => {
    return {
        type: SET_TALENT_STATE,
        payload: state,
    }
}

export const updateTalentMultiple = (token, table, value, loading) => {
    return {
        type: UPDATE_TALENT_MULTIPLE,
        token: token,
        table: table,
        value: value,
        loading: loading
    }
}

export const updateTalentMultipleSuccess = (data) => {
    return {
        type: UPDATE_TALENT_MULTIPLE_SUCCESS,
        data: data?.data,
    }
}

export const updateTalentMultipleLevel = (token, table, key, value) => {
    return {
        type: UPDATE_TALENT_MULTIPLE_LEVEL,
        token: token,
        table: table,
        key: key,
        value: value
    }
}

export const updateTalentMultipleLevelSuccess = (data) => {
    return {
        type: UPDATE_TALENT_MULTIPLE_LEVEL_SUCCESS,
        data: data?.data,
    }
}

export const deleteTalentMultiple = (token, table, value) => {
    return {
        type: DELETE_TALENT_MULTIPLE,
        token: token,
        table: table,
        value: value
    }
}

export const deleteTalentMultipleSuccess = (data) => {
    return {
        type: DELETE_TALENT_MULTIPLE_SUCCESS,
        data: data?.data,
    }
}

export const presentationTalent = (token, query) => {
    return {
        type: PRESENTATION_TALENT,
        token: token,
        query: query
    }
}

export const presentationTalentSuccess = (data) => {
    return {
        type: PRESENTATION_TALENT_SUCCESS,
        data: data,
    }
}

export const updateSemiProfile = (token, query, loading, intl) => {
    return {
        type: UPDATE_SEMI_PROFILE,
        token: token,
        query: query,
        loading: loading,
        intl
    }
}

export const updateSemiProfileSuccess = (data) => {
    return {
        type: UPDATE_SEMI_PROFILE_SUCCESS,
        data: data,
    }
}

export const updateProfileStatus = (token) => {
    return {
        type: UPDATE_PROFILE_STATUS,
        token: token
    }
}

export const updateProfileStatusSuccess = () => {
    return {
        type: UPDATE_PROFILE_STATUS_SUCCESS,
    }
}

export const refetchTalent = () => {
    return {
        type: REFETCH_TALENT,
    }
}

export const getSalaryDetails = (token, country, salary, from_currency, to_currency) => {

    return {
        type: SALARY_DETAILS,
        token: token,
        country: country,
        salary: salary,
        from_currency: from_currency,
        to_currency: to_currency
    }
}

export const getSalaryDetailsSuccess = (data) => {
    return {
        type: SALARY_DETAILS_SUCCESS,
        data: data,
    }
}

export const getSalaryTaxDetails = (token, country, salary, from_currency, to_currency) => {

    return {
        type: SALARY_TAX_DETAILS,
        token: token,
        country: country,
        salary: salary,
        from_currency: from_currency,
        to_currency: to_currency
    }
}

export const getSalaryTaxDetailsSuccess = (data) => {
    return {
        type: SALARY_TAX_DETAILS_SUCCESS,
        data: data,
    }
}

export const skillLangHobby = (data) => {
    return {
        type: SKILL_LANG_HOBBY,
        data: data,
    }
}

export const getTimesheets = (token, query) => {
    return {
        type: GET_TIMESHEETS,
        token: token,
        query: query
    }
}

export const getTimesheetsSuccess = (data) => {
    return {
        type: GET_TIMESHEETS_SUCCESS,
        data: data?.data,
    }
}

export const upsertTimesheets = (token, query, updateReducer = true) => {
    return {
        type: UPSERT_TIMESHEETS,
        token: token,
        query: query,
        updateReducer: updateReducer
    }
}

export const upsertTimesheetsSuccess = (data) => {
    return {
        type: UPSERT_TIMESHEETS_SUCCESS,
        data: data,
    }
}

export const getExpenseReports = (token, query) => {
    return {
        type: GET_EXPENSE_REPORTS,
        token: token,
        query: query
    }
}

export const getExpenseReportsSuccess = (data) => {
    return {
        type: GET_EXPENSE_REPORTS_SUCCESS,
        data: data?.data,
    }
}

export const upsertExpenseReports = (token, query) => {
    return {
        type: UPSERT_EXPENSE_REPORTS,
        token: token,
        query: query
    }
}

export const upsertExpenseReportsSuccess = (data) => {
    return {
        type: UPSERT_EXPENSE_REPORTS_SUCCESS,
        data: data,
    }
}

export const deleteExpenseReports = (token, id) => {
    return {
        type: DELETE_EXPENSE_REPORTS,
        token: token,
        id: id
    }
}

export const deleteExpenseReportsSuccess = (data) => {
    return {
        type: DELETE_EXPENSE_REPORTS_SUCCESS,
        data: data,
    }
}

export const getContractorInvoices = (token, query) => {
    return {
        type: GET_CONTRACTOR_INVOICE,
        token: token,
        query: query
    }
}

export const getContractorInvoicesSuccess = (data) => {
    return {
        type: GET_CONTRACTOR_INVOICE_SUCCESS,
        data: data?.data,
    }
}

export const getContractorInvoicesExplain = (token, form) => {
    return {
        type: GET_CONTRACTOR_INVOICE_EXPLAIN,
        token: token,
        form: form
    }
}

export const getContractorInvoicesExplainSuccess = (data) => {
    return {
        type: GET_CONTRACTOR_INVOICE_EXPLAIN_SUCCESS,
        data: data,
    }
}

export const upsertContractorInvoices = (token, query) => {
    return {
        type: UPSERT_CONTRACTOR_INVOICE,
        token: token,
        query: query
    }
}

export const upsertContractorInvoicesSuccess = (data) => {
    return {
        type: UPSERT_CONTRACTOR_INVOICE_SUCCESS,
        data: data,
    }
}

export const upsertContractorInvoicesValidationError = (data) => {
    return {
        type: UPSERT_CONTRACTOR_INVOICE_VALIDATION_ERROR,
        data: data,
    }
}

export const getHolidays = (token, query) => {
    return {
        type: GET_HOLIDAYS,
        token: token,
        query: query
    }
}

export const getHolidaysSuccess = (data) => {
    return {
        type: GET_HOLIDAYS_SUCCESS,
        data: data?.data,
    }
}

export const upsertHolidays = (token, query, intl) => {
    return {
        type: UPSERT_HOLIDAYS,
        token: token,
        query: query,
        intl: intl
    }
}

export const upsertHolidaysSuccess = (data) => {
    return {
        type: UPSERT_HOLIDAYS_SUCCESS,
        data: data,
    }
}

export const getBonus = (token, query) => {
    return {
        type: GET_BONUS,
        token: token,
        query: query
    }
}

export const getBonusSuccess = (data) => {
    return {
        type: GET_BONUS_SUCCESS,
        data: data?.data,
    }
}

export const upsertBonus = (token, query) => {
    return {
        type: UPSERT_BONUS,
        token: token,
        query: query
    }
}

export const upsertBonusSuccess = (data) => {
    return {
        type: UPSERT_BONUS_SUCCESS,
        data: data,
    }
}

export const upsertSurvey = (token, query) => {
    return {
        type: UPSERT_SURVEY,
        token: token,
        query: query
    }
}

export const upsertSurveySuccess = (data) => {
    return {
        type: UPSERT_SURVEY_SUCCESS,
        data: data,
    }
}

export const updateTalentBanking = (token, query) => {
    return {
        type: UPDATE_TALENT_BANKING,
        token: token,
        query: query
    }
}

export const updateTalentBankingSuccess = (data) => {
    return {
        type: UPDATE_TALENT_BANKING_SUCCESS,
        data: data,
    }
}

export const getTalentEmployment = (token) => {
    return {
        type: GET_TALENT_EMPLOYMENT,
        token: token,
    }
}

export const getTalentEmploymentSuccess = (data) => {
    return {
        type: GET_TALENT_EMPLOYMENT_SUCCESS,
        data: data,
    }
}