// Import the redux-saga/effects
import {call, delay, put, takeEvery} from 'redux-saga/effects'

// Import all action and api's
import {GET_CHATGPT, CHECK_JOB, CHECK_CENSOR_TEXT, FETCH_SYNONYMS_REQUEST} from '../action-types'

// Import all api's
import {getChatgptRequest, checkAutoJobRequest, checkCensorTextRequest, ChatgptESRequest} from '../api/Chatgpt'

import {getChatgptSuccess, checkJobSuccess, checkCensorTextSuccess, fetchSynonymsSuccess, fetchSynonymsFailure} from '../actions/Chatgpt'

import {fetchError, fetchExpired, fetchStart, fetchSuccess} from '../actions/Common'

function* getChatgpt({token, chat_type, params}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getChatgptRequest, token, chat_type, params)
        if (response.status === 200) {
            yield put(getChatgptSuccess(response?.data, chat_type))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* checkGpt({token, id}) {
    try {
        //yield put(fetchStart())
        const response = yield call(checkAutoJobRequest, token, id)
        if (response.status === 200) {
            yield put(checkJobSuccess(response?.data))
            yield put(fetchSuccess())
        }
    } catch (error) {
        console.log(error)
    }
}

function* checkCensorText({token, text}) {
    try {
        yield put(fetchStart())
        const response = yield call(checkCensorTextRequest, token, text)
        if (response.status === 200) {
            yield put(checkCensorTextSuccess(response?.data))
            yield put(fetchSuccess())
        }
    } catch (error) {
        console.log(error)
    }
}

function* fetchSynonyms(action) {
    try {
        yield delay(2000);
      const { token, message } = action.payload;
      const response = yield call(ChatgptESRequest, token, message);
  
      if (response?.data && response?.data?.synonyms) {
        yield put(fetchSynonymsSuccess(response.data.synonyms));
      } else {
        yield put(fetchSynonymsFailure('No synonyms found'));
      }
    } catch (error) {
      yield put(fetchSynonymsFailure(error.message));
    }
}

// Export the saga (todo-saga)
export default function* todoSaga() {
    yield takeEvery(GET_CHATGPT, getChatgpt)
    yield takeEvery(CHECK_JOB, checkGpt)
    yield takeEvery(CHECK_CENSOR_TEXT, checkCensorText)
    yield takeEvery(FETCH_SYNONYMS_REQUEST, fetchSynonyms)
}