// Import the redux-saga/effects
import {call, put, takeEvery} from 'redux-saga/effects'

// Import all action and api's
import {
    DELETE_TALENT_MULTIPLE,
    GET_TALENT, GET_TALENTS_ES,
    GET_TALENTS, GET_TALENTS_COUNT,
    PRESENTATION_TALENT,
    SALARY_DETAILS, SALARY_TAX_DETAILS,
    UPDATE_PROFILE_STATUS,
    UPDATE_SEMI_PROFILE,
    UPDATE_TALENT,
    UPDATE_TALENT_MULTIPLE,
    UPDATE_TALENT_MULTIPLE_LEVEL,
    GET_TIMESHEETS, UPSERT_TIMESHEETS, UPSERT_EXPENSE_REPORTS, GET_EXPENSE_REPORTS, DELETE_EXPENSE_REPORTS,
    GET_CONTRACTOR_INVOICE, UPSERT_CONTRACTOR_INVOICE, GET_HOLIDAYS, UPSERT_HOLIDAYS, GET_BONUS, UPSERT_BONUS,
    UPSERT_SURVEY, GET_CONTRACTOR_INVOICE_EXPLAIN,
    STORE_TALENT_RATING, UPDATE_TALENT_BANKING, GET_TALENT_EMPLOYMENT,
    GET_TALENTS_BY_IDS
} from '../action-types'

// Import all api's
import {
    deleteTalentMultipleRequest,
    getSalaryDetailsRequest,
    getSalaryTaxDetailsRequest,
    getTalentRequest,
    getTalentsRequest,
    presentationTalentRequest,
    updateProfileStatusRequest,
    updateSemiProfileRequest,
    updateTalentMultipleLevelRequest,
    updateTalentMultipleRequest,
    updateTalentRequest,
    getTalentsESRequest,
    upsertTimesheetsRequest,
    upsertExpenseReportsRequest,
    deleteExpenseReportsRequest,
    upsertContractorInvoicesRequest,
    upsertHolidaysRequest,
    deleteHolidaysRequest,
    upsertBonusRequest,
    upsertSurveyRequest,
    getContractorInvoicesExplainRequest,
    storeTalentRatingRequest,
    updateTalentBankingDetailsRequest,
    getTalentEmploymentRequest,
    getTalentsByIds
} from '../api/Talent'

import {
    deleteTalentMultipleSuccess,
    getSalaryDetailsSuccess,
    getTalentSuccess,
    getTalentsSuccess,upsertContractorInvoicesValidationError,
    presentationTalentSuccess,
    updateProfileStatus,
    updateProfileStatusSuccess,
    updateSemiProfileSuccess,
    updateTalentMultipleLevelSuccess,
    updateTalentMultipleSuccess,
    updateTalentSuccess,
    getSalaryTaxDetailsSuccess,
    getTalentsCountSuccess,
    getTalentsSuccessES,
    getTimesheetsSuccess,
    upsertTimesheetsSuccess,
    getExpenseReportsSuccess,
    upsertExpenseReportsSuccess,
    deleteExpenseReportsSuccess, getContractorInvoicesSuccess, upsertContractorInvoicesSuccess, getHolidaysSuccess,
    upsertHolidaysSuccess, getBonusSuccess, upsertBonusSuccess, upsertSurveySuccess, getContractorInvoicesExplainSuccess,
    storeTalentRatingSuccess,
    updateTalentBankingSuccess,
    getTalentEmploymentSuccess,
    getTalentsFailure,
    getTalentsByIdsSuccess
} from '../actions/Talent'

import {fetchError, fetchExpired, fetchStart, fetchSuccess} from '../actions/Common'
import { toast } from 'react-toastify'


function* getTalentsByIdsEs(action) {
    try {
        const { token, ids } = action.payload;
        const response = yield call(getTalentsByIds, token, ids);
        yield put(getTalentsByIdsSuccess(response.data));
    } catch (error) {
        yield put(getTalentsFailure(error.message));
    }
}

function* getTalent({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getTalentRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getTalentSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getTalents({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getTalentsRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getTalentsSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getTalentsES({token, query, locale}) {
    try {
        yield put(fetchStart())
        const response = yield call(getTalentsESRequest, token, query, locale)
        if (response.status === 200) {
            yield put(getTalentsSuccessES(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getTalentsCount({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getTalentsRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getTalentsCountSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* storeTalentRating({ token, values, intl}) {
    try {
      yield put(fetchStart());
      const response = yield call(storeTalentRatingRequest, token, values);
      if (response.status === 200) {
        yield put(storeTalentRatingSuccess(response.data?.data));
        yield put(fetchSuccess());
        toast.success(intl.formatMessage({id: "rating.success"})) 
      }
      if (response.status === 401) {
        yield put(fetchExpired());
      }
      if (response.status === 500) {
        yield put(fetchError(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  }

function* updateTalent({token, query, loading = false, reload = false, intl, typeUpdate}) {
    try {
        if (loading) {
            yield put(fetchStart())
        }



        const response = yield call(updateTalentRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                if (loading) {
                    yield put(updateTalentSuccess(response.data))
                }
                yield put(fetchSuccess())
                typeUpdate === "preferences" &&
                toast.success(intl.formatMessage({id: "preferencesUpdated"}));
                typeUpdate === "coordonées" &&
                toast.success(intl.formatMessage({id: "contactDetailsUpdated"}));
                typeUpdate === "avatar" && toast.success(intl.formatMessage({id: "avatarUpdated"}));
                typeUpdate === "personalInfo" &&
                toast.success(intl.formatMessage({id: "personalInfoUpdated"}));
                typeUpdate === "documents" &&
                toast.success(intl.formatMessage({id: "DocumentsUpdated"}));
                if (reload) {
                    window.location.reload()
                }
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
            toast.error(intl.formatMessage({ id: "error401" }));
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
            toast.error(intl.formatMessage({ id: "server_error_portage" }));
        }
    } catch (error) {
        console.log(error)
        toast.error(intl.formatMessage({ id: "global_error_portage" }));
    }
}

function* updateTalentMultiple({token, table, value, loading = true}) {
    try {
        if (loading) {
            yield put(fetchStart())
        }

        const response = yield call(updateTalentMultipleRequest, token, table, value)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                if (loading) {
                    yield put(updateTalentMultipleSuccess(response.data))
                    yield put(fetchSuccess())
                }
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* updateTalentMultipleLevel({token, table, key, value}) {
    try {
        yield put(fetchStart())
        const response = yield call(updateTalentMultipleLevelRequest, token, table, key, value)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(updateTalentMultipleLevelSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* deleteTalentMultiple({token, table, value}) {
    try {
        yield put(fetchStart())
        const response = yield call(deleteTalentMultipleRequest, token, table, value)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(deleteTalentMultipleSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* presentationTalent({token}) {
    try {
        yield put(fetchStart())
        const response = yield call(presentationTalentRequest, token)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(presentationTalentSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* updateSemiProfile({token, query, loading = true, intl}) {
    try {
        if (loading) {
            yield put(fetchStart())
        }
        const response = yield call(updateSemiProfileRequest, token, query)
        if (response.status === 200) {
            yield put(updateSemiProfileSuccess(response?.data))
            if (loading) {
                yield put(fetchSuccess())
                //yield put(updateProfileStatus(token))
                toast.success(intl.formatMessage({id: "success_form"}))
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* updateTalentProfileStatus({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(updateProfileStatusRequest, token, query)
        if (response.status === 200) {
            yield put(updateProfileStatusSuccess())
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getSalaryDetails({token, country, salary, from_currency, to_currency}) {
    try {
        yield put(fetchStart())
        const response = yield call(getSalaryDetailsRequest, token, country, salary, from_currency, to_currency)
        if (response.status === 200) {
            yield put(getSalaryDetailsSuccess(response?.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getSalaryTaxDetails({token, country, salary, from_currency, to_currency}) {
    try {
        yield put(fetchStart())
        const response = yield call(getSalaryTaxDetailsRequest, token, country, salary, from_currency, to_currency)
        if (response.status === 200) {
            yield put(getSalaryTaxDetailsSuccess(response?.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getTimesheets({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getTalentsRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getTimesheetsSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* upsertTimesheets({token, query, updateReducer = true}) {
    try {
        yield put(fetchStart())
        const response = yield call(upsertTimesheetsRequest, token, query)
        if (response.status === 200) {
            if (updateReducer) {
                yield put(upsertTimesheetsSuccess(response.data))
            }
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getExpenseReports({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getTalentsRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getExpenseReportsSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* upsertExpenseReports({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(upsertExpenseReportsRequest, token, query)
        if (response.status === 200) {
            yield put(upsertExpenseReportsSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* deleteExpenseReports({token, id}) {
    try {
        yield put(fetchStart())
        const response = yield call(deleteExpenseReportsRequest, token, id)
        if (response.status === 200) {
            yield put(deleteExpenseReportsSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getContractorInvoices({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getTalentsRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getContractorInvoicesSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getContractInvoiceExplain({token, form}) {
    try {
        yield put(fetchStart())
        const response = yield call(getContractorInvoicesExplainRequest, token, form)
        if (response.status === 200) {
            yield put(getContractorInvoicesExplainSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* upsertContractorInvoices({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(upsertContractorInvoicesRequest, token, query)
        if (response.status === 200) {
            yield put(upsertContractorInvoicesSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 423) {
            yield put(upsertContractorInvoicesValidationError(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getHolidays({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getTalentsRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getHolidaysSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* upsertHoliday({token, query, intl}) {
    try {
        yield put(fetchStart())
        const response = yield call(upsertHolidaysRequest, token, query)
        if (response.status === 200) {
            yield put(upsertHolidaysSuccess(response.data))
            yield put(fetchSuccess())
            toast.success(intl.formatMessage({id: "success_form"}))
        }
        if (response.status === 401) {
            yield put(fetchExpired())
            toast.error(intl.formatMessage({ id: "error401" }));
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
            toast.error(intl.formatMessage({ id: "server_error_portage" }));
        }
    } catch (error) {
        console.log(error)
        toast.error(intl.formatMessage({ id: "global_error_portage" }));
    }
}

function* getBonus({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(getTalentsRequest, token, query)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getBonusSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* upsertBonus({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(upsertBonusRequest, token, query)
        if (response.status === 200) {
            yield put(upsertBonusSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* upsertSurvey({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(upsertSurveyRequest, token, query)
        if (response.status === 200) {
            yield put(upsertSurveySuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* updateTalentBanking({token, query}) {
    try {
        yield put(fetchStart())
        const response = yield call(updateTalentBankingDetailsRequest, token, query)
        if (response.status === 200) {
            yield put(updateTalentBankingSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* getTalentEmployment({token}) {
    try {
        yield put(fetchStart())
        const response = yield call(getTalentEmploymentRequest, token)
        if (response.status === 200) {
            yield put(getTalentEmploymentSuccess(response.data))
            yield put(fetchSuccess())
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}


// Export the saga (todo-saga)
export default function* todoSaga() {
    yield takeEvery(GET_TALENT, getTalent)
    yield takeEvery(GET_TALENTS, getTalents)
    yield takeEvery(GET_TALENTS_COUNT, getTalentsCount)
    yield takeEvery(UPDATE_TALENT, updateTalent)
    yield takeEvery(UPDATE_TALENT_MULTIPLE, updateTalentMultiple)
    yield takeEvery(UPDATE_TALENT_MULTIPLE_LEVEL, updateTalentMultipleLevel)
    yield takeEvery(DELETE_TALENT_MULTIPLE, deleteTalentMultiple)
    yield takeEvery(PRESENTATION_TALENT, presentationTalent)
    yield takeEvery(UPDATE_SEMI_PROFILE, updateSemiProfile)
    yield takeEvery(UPDATE_PROFILE_STATUS, updateTalentProfileStatus)
    yield takeEvery(SALARY_DETAILS, getSalaryDetails)
    yield takeEvery(SALARY_TAX_DETAILS, getSalaryTaxDetails)
    yield takeEvery(GET_TALENTS_ES, getTalentsES)
    yield takeEvery(GET_TIMESHEETS, getTimesheets)
    yield takeEvery(UPSERT_TIMESHEETS, upsertTimesheets)
    yield takeEvery(GET_EXPENSE_REPORTS, getExpenseReports)
    yield takeEvery(UPSERT_EXPENSE_REPORTS, upsertExpenseReports)
    yield takeEvery(DELETE_EXPENSE_REPORTS, deleteExpenseReports)
    yield takeEvery(GET_CONTRACTOR_INVOICE, getContractorInvoices)
    yield takeEvery(UPSERT_CONTRACTOR_INVOICE, upsertContractorInvoices)
    yield takeEvery(GET_HOLIDAYS, getHolidays)
    yield takeEvery(UPSERT_HOLIDAYS, upsertHoliday)
    yield takeEvery(GET_BONUS, getBonus)
    yield takeEvery(UPSERT_BONUS, upsertBonus)
    yield takeEvery(UPSERT_SURVEY, upsertSurvey)
    yield takeEvery(STORE_TALENT_RATING, storeTalentRating)
    yield takeEvery(GET_CONTRACTOR_INVOICE_EXPLAIN, getContractInvoiceExplain)
    yield takeEvery(UPDATE_TALENT_BANKING, updateTalentBanking)
    yield takeEvery(GET_TALENT_EMPLOYMENT, getTalentEmployment)
    yield takeEvery(GET_TALENTS_BY_IDS, getTalentsByIdsEs)
}